.dashboard-header {
    background-color: white;
    width: 100%;
    left: 0;
    position: fixed;
    z-index: 101;
    
}
.dashboard-header {
    color: white;
}
#dashboard-intro {
    padding-top: 15vh;
}
.dashboard-middle {
    padding-bottom: 10vh;
    background-color: rgb(248, 248, 248);
    /* border-top: 1px solid rgb(219, 219, 219);  */
    border-bottom: 1px solid rgb(219, 219, 219); 
    padding-top: 20px;
}
.Individual-Matches {
    width: 286px;
    height: 50px;
    font-family: Dosis;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
}

.dashboard-title {
}

.wowcentered {
    text-align: center;
}

.wowcentered1 {
    text-align: center;
    font-size: 15px;
}

#align-left {
    text-align: left;
    padding-bottom: 0 !important;
}
.card {
    margin: 10px;
}
.dash-tabs {
    /* width: 100%; */
    padding: 0px 10%;
    /* padding-bottom: 10px; */
    margin-right: 0;
    border-bottom: 0;
    position: sticky;
    top: 9.7vh;
    margin-top: 0;
    background: white;
    z-index: 20;
    border-bottom: 1px solid #dee2e6;
    /* border-top: 1px solid gray; */
}
.dash-tabs .nav-link.active  {
    /* border-radius: .25rem; */
    /* background-color: rgb(248, 248, 248) !important; */
    /* border-bottom: 0; */
    border-bottom: 1px solid #dee2e6 !important;
}
.dash-tabs  a {
    
    color: rgb(133, 133, 133);
}
.dash-tabs a:focus {
    outline: none;
}
@media (max-width: 600px) {
    .dash-tabs  a {
        font-size: 14px !important;
    padding: .25rem .5rem;
    margin: 4px;
    }
}

.Home {
    width: 64px;
    height: 31px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
}

.Group-Activities {
    width: 173px;
    height: 31px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
}

.Our-Chapters {
    width: 144px;
    height: 31px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
}

.Our-FAQ-Section {
    width: 181px;
    height: 31px;
    font-family: Overpass;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
}

.Rectangle {
    border: solid 1px #979797;
    background-color: #ffffff;
}

.CLICK-TO-CONFIRM-MEE {
    width: 239px;
    height: 48px;
    border-radius: 2px;
    box-shadow: 2px 2px 4px 2px rgba(171, 171, 171, 0.5);
}

.customspace {
    width: 242px;
    max-width: 242px;
    border-right-width: 3px;
}

.customspaceagain {
    width: 885px;
    max-width: 885px;
}

.textstuff {
    color: #02295f;
    text-decoration: underline;
    font-size: 18px;
}

.background {
    background-color: #f4f4f4;
    width: 240px;
    height: 240px;
}

.namecheck {
    display: block;
    white-space: nowrap;
    width:85%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.my-button {
    display: flex;
    align-items: center;
}

.border-right {
    border: solid 2px #979797;
}