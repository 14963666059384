@import "utilities/variables";

@mixin verticalAlignMiddle() {
    position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Dosis', sans-serif;
 }
 ul {
    list-style: none;
    padding: 0;
 }
 .btn.btn-primary {
     background: $primary-color;
     border-color: $primary-color;
     box-shadow: none;
 }
 .btn.btn-primary:hover{
    background: $hover-color !important;
    border-color: $hover-color !important;
 }
 
 a {
    color: $primary-color;
    /* font-size: 20px; */
    transition: 0s;
    transition: background-color .25s, border .25s, box-shadow .25s;
  
    /* text-decoration: underline; */
 }
 .underline {
    text-decoration: underline;
 }
  .hero-button, .donate-button, .signup-button, .dark-button, .btn, .nice-button, .nice-yellow-button {
    border-radius: 5px !important;
}
  .hero-button:active, .donate-button:active, .signup-button:active, 
  .dark-button:active, .btn:active, .nice-button:active, .nice-yellow-button:active {
    filter: brightness(80%);
    /* transition: .5s; */

    /* transform: scale(.98); */

  }
  #counter-section {
    border-radius: 5px !important;

  }
  
 body {
    font-family: "Overpass", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.8;
    font-weight: 300;
    font-size: 16px;
    color: #555555;
    background-color: white;
 }
 .signup-button {
    background-color: $primary-color;
    padding: 6px 25px !important;
    /* border-radius: 8px; */
    color: white !important;
 }
 .signup-button:hover {
  background-color: #977a22;
 }
 .signup-button:active {
  background-color: #7e5e00;
 }
 .large-signup-button {
    background-color: #f8ce4e;
    padding: 10px 38px;
    /* border-radius: 8px; */
    color: white !important;
    line-height: 1.3;
 }
 .large-signup-button:hover {
  background-color: #927a32;
 }
 .large-signup-button:active {
  background-color: #7e5e00;
 }
 .nice-button {
 color: gray;
 border: 2px solid gray;  
 padding: 16px 48px;
 }
 .nice-button:hover {
    background: gray;
    color: white;
  
   }
   .dark-button {
    color: rgb(0, 55, 143);
    border: 2px solid rgb(0, 55, 143);  
    padding: 16px 48px;
   }
   .dark-button:hover {
       background: rgb(0, 55, 143);
       color: white;
  
      }
  
   .nice-yellow-button {
    color: $primary-color;
    border: 2px solid $primary-color;  
    padding: 16px 48px;
   }
   .nice-yellow-button:hover {
       background: $primary-color;
       color: white;
  
   }
   #header .donate-button {
    border: 1px solid #DFDFDF;
    /* color: black; */
    /* box-shadow: 0px 0px 10px #888888; */
  
    /* background-color: white; */
    padding: 5px 23px;
    border-radius: 2px;
   }
   #header .donate-button:hover {
       border: 1px solid rgb(93, 93, 93);
       /* color: black; */
       /* box-shadow: 0px 0px 20px #888888; */
  
  
   }
   /* #header .donate-button {
    margin-top: 10px;
 color: #6D6C6C;
 border: 2px solid #6D6C6C;  
 padding: 16px 48px;
 }
 #header .donate-button:hover {
    border: 2px solid black;
    color: black;
  
 } */
   .header-button {
    color: white !important;
    border: 2px solid $primary-color;  
    background: $primary-color;
    padding: 10px 30px;
   }
   .header-button:hover {
       background: $hover-color;
       border: 2px solid $hover-color;  
       color: white;
   }
   .hero-button {
    color: white;
    border: 2px solid $primary-color;  
    background: $primary-color;
    padding: 15px 25px;
    /* padding: 1.5vh 2.5vh; */

    display: inline-block;
    font-size: 18px;
    margin: 16px 8px;
   }
   .hero-button:hover {
       background: $hover-color;
       border: 2px solid $hover-color;  
       color: rgb(231, 231, 231);
   }
  
 #light-header {
  
    color: white;
    margin-top: 10px;
    position: absolute;
    /* z-index: 10; */
    text-align: justify;
    width: 100%;
    left: 0;
   
   
 }
 /* #light-header.scrolled {
    background-color: black;
 } */
 #light-header a {
    color: white;
 }
 #light-header .donate-button {
    border: 1px solid #DFDFDF;
    /* color: black; */
    box-shadow: 0px 0px 10px #888888;
  
    /* background-color: white; */
    padding: 5px 23px;
    border-radius: 2px;
 }
 #light-header .donate-button:hover {
    border: 1px solid white;
    color: white;
    box-shadow: 0px 0px 20px #888888;
  
 }
 /* #light-header .donate-button {
    border: 1px solid #DFDFDF;
    color: #DFDFDF;
    padding: 10px 30px;
 }
 #light-header .donate-button:hover {
    border: 1px solid white;
    color: white;
 } */
 @keyframes nav-in {
    100% {
        transform: translateY(0);
    }
 }
 @keyframes nav-out {
    100% {
        transform: translateY(-10vh);
    }
 }
 @media (max-width: 991px) {
  
   
    .signup {
        /* height: 100vh;
        overflow: hidden; */
    }
    #light-header {
        top: 0 !important;
        transform: translateY(-10vh);
        position: fixed;
        margin-top: 0vh;
        /* margin-top: 0; */
        z-index: 101;
        background-color: white;
        width: 100%;
        margin-left: 0;
        left: 0;
        vertical-align: middle;
        animation: nav-in .5s forwards;
    }
    .navbar-brand {
        color: rgba(0,0,0,.5) !important;
    }
    #light-header a {
        color: rgba(0,0,0,.5);
        /* margin-left: 5%; */
    }
    #light-header a:hover {
        color: rgb(0, 0, 0);
    }
    #light-header .donate-button {
        border: 0px solid #DFDFDF;
        /* color: black; */
        box-shadow: 0px 0px 0px #888888;
        padding-left: 0;
        /* background-color: white; */
        /* padding: 10px 15px;
        border-radius: 25px; */
    }
    #light-header .donate-button:hover {
        border: 0px solid white;
        color: black;
        box-shadow: 0px 0px 0px #888888;
   
       
    }
  
    #header .donate-button {
        border: 0px solid #DFDFDF;
        /* color: black; */
        box-shadow: 0px 0px 0px #888888;
        padding-left: 0;
        /* background-color: white; */
        /* padding: 10px 15px;
        border-radius: 25px; */
    }
    #header .donate-button:hover {
        border: 0px solid white;
        color: black;
        box-shadow: 0px 0px 0px #888888;
   
       
    }
    .signup-button {
        /* background-color: transparent;
        color: gray !important;
        padding:  0 !important; */
        display: inline;
    }
    .signup-button:hover {
      /* background-color: transparent;
      color: black !important; */
    }
    .signup-button:active {
      background-color: transparent;
    }
 }
 #header {
    transform: translateY(-10vh);
    /* margin-top: 0; */
    position: fixed;
    z-index: 101;
    background-color: white;
    width: 100%;
    margin-left: 0;
    left: 0;
    top: 0;
    vertical-align: middle;
    animation: nav-in .5s forwards;
    box-shadow: 0 0 2px rgb(202, 202, 202);
  
 }
 .nav-item {
    /* margin-top: 50px !important; */
    font-size: 19px;
    margin: 8px;
 }
 .navbar-brand {
    /* margin-left: 20px; */
 }
 .centered {
    text-align: center;
 }
 .icon-section {
    background: $primary-color;
    text-align: center;
    padding: 3% 0 3% 0;
 }
 #gray-background {
    background: #F5F5F5;
 }
 .border-bottom {
    padding-bottom: 10%;
    border-bottom: 1px solid black;
 }
 .sub-heading {
    color: rgb(175, 175, 175);
    font-size: 18px;
 }
 .block-41-text {
    font-size: 21px;
 }
 .carousel-caption p{
    color: black;
    width: 60%;
    margin-left: 20%;
    font-size: 30px;
 }
 .carousel-caption h3{
    font-size: 20px;
 }
 .carousel-item {
    /* height: 90vh; */
    overflow: hidden;
 }
 .carousel-item img{
    height: 100vh;
    /* width: 30vw; */
    object-fit: cover;
    object-position: top;
 }
 #testimonials .carousel-item {
    height: 30vh;
 }
 .active {
    /* background-color: black !important; */
 }
 .carousel-indicators li{
    /* background-color: black !important; */
  
 }
 .carousel-control-prev, .carousel-control-next {
    /* display: none; */
 }
 .heading {
    font-size: 50px;
 }
  
      .add-spacing {
          margin-bottom: 10px;
      }
  
      .footer-text-style {
          color: white;
      }
  
      .centered-footer {
          margin-top: 50px;
          text-align: center;
      }
      .icon {
        font-size: 12px;
      }  
      .icon-text {
        font-size: 15px;
        margin-left: 10px;
      }  
      #HIWActionSection {
          background: $primary-color;
      }
      .white {
          color: white;
      }
      #margin-left {
          margin-left: 50px;
      }
      .big-and-mini-card-right {
          margin-top: 15px;
          display: inline-block;
          width: 80%;
          /* vertical-align: middle; */
  
      }
      .big-and-mini-card-left {
        margin-top: 20px;
        color: $primary-color;
        width: 10%;
        margin-right: 5%;
          vertical-align: top;
          display: inline-block;
  
      }
      .big-and-mini-card-left svg {
  
        font-size: 40px;
      }
      .remove-padding {
          padding-left: 0;
      }
      .BigAndMiniSection {
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        padding: 5% 8px 5% 20px;
      }
      #dark-gray-background {
          padding-top: 2% !important;
          /* background-color: #404040; */
          border-bottom: 1px solid white;
          color: white;
          border-top: 1px solid rgb(228, 228, 228);
          padding-bottom: 9%;
          /* borderTop: "1px solid #EEEEEE" */
      }
      .heading-light {
          font-size: 50px;
          /* color: white; */
          margin-top: 50px;
      }
      .light {
        color: rgb(54, 54, 54);
      }
      #dark-yellow {
        background: $primary-color;
        border: 2px solid $primary-color;
      }
      #gray-background {
          background-color: #fcfcfc;
      }
      #border-bottom {
        background-color: #fcfcfc;
  
          border-bottom: 1px solid #EEEEEE;
      }
      .news-font {
          font-size: 40px;
          margin-bottom: 50px;
      }
      #grayscale {
          filter: grayscale(0%);
      }
      .signup-style {
          /* background-color: #6367FF; */
          background-color: black;
          height: 100vh;
        background-position: center;
          /* background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(./../images/img_12.jpg); */
  -webkit-background-size: cover;
  -moz-background-size:  cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-position: fixed; */
  position: relative;
      }
      .signup-image {
        height: 100vh;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size:  cover;
        -o-background-size: cover;
        background-size: cover;
        position: fixed;
        top: 0;
        z-index: 0;
        left: 0;
        width: 50vw;
        margin: 0 !important;
      }
      .org-signup-image {
        height: 100vh;
        background-image: url(/images/Mockups/Laptop/smartmockups_kckms64m.jpg);
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size:  cover;
        -o-background-size: cover;
        background-size: cover;
        position: fixed;
        top: 0;
        z-index: 0;
        left: 0;
        width: 50vw;
        margin: 0 !important;
      }
      .signup-right {
          background: white;
      }
     
      @media (max-width: 991px) {
        .login-style {
            display: none !important;
        }
        .signup-style {
            display: none !important;
        }
        .logo {
            left: 5% !important;
        }
        .complete-centered {
            /* margin-top: 30% !important; */
        }
    }
      .login-style {
          display: block;
        /* background-color: #6367FF; */
        background-color: black;
        height: 100vh;
        background-position: center;
        /* background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(./../images/img_12.jpg); */
 -webkit-background-size: cover;
 -moz-background-size:  cover;
 -o-background-size: cover;
 background-size: cover;
    }
  
  
      .logo-style {
          color: black;
          font-size: 25px;
      }
      .signup-text {
          font-size: 40px;
      }
      #remove-margin {
          margin-left: 0  !important;
          margin-right: 0 !important;
          padding: 0 !important;
      }
      .fill-height {
         
          width: 100%;
          /* height: 100vh; */
          overflow-x: hidden; /* To get rid of horizontal scrolbar on certain screen sizes */
          /* height: 100%; */
          position: absolute;
          top: 0;
          z-index: 200;
          background: white;
      }
      .complete-centered {
          /* width: 100%;
        position: absolute;
        left: 50%;*/
        margin: 20% 5% 10% 5%;
        /* transform: translate(-50%, -50%);  */
      }
     
    input {
        border-radius: 50%;
    }
    .form-control {
        border-radius: 0px !important;
  
    }
    .signup-image {
        margin-top: 30%;
        margin-left: 5%;
    }
    .logo {
        position: absolute;
        color: black;
        right: 5%;
        top: 5%;
        font-size: 40px;
        z-index: 100;
        font-size: 25px;
        width: 113px;
        text-decoration: none;
  
    }
    .logo:hover {
        color: rgb(129, 129, 129);
    }
    .bolded {
        font-weight: 600;
    }
    .have-account {
        color: white;
        display: inline-block;
        margin-top: 80%;
    }
    .logo-text {
        text-align: center;
    }
    .signup-title h2{
        color: white ;
        margin-top: 10%;
        /* text-align: left; */
        /* margin-left: 25%; */
  
  
    }
    .signup-description {
        /* text-align: center; */
    }
    .signup-description h1{
        color: white;
        width: 50%;
        /* text-align: left; */
        margin-left: 25%;
    }
    .signup-link {
        color: white;
        margin-top: 80%;
        /* margin-left: 25%; */
        /* text-align: left; */
    }
    .inline {
        display: inline-block;
    }
    #testimonials {
        background-color: #f6f4f471;
    }
    #testimonials h1 {
        font-size: 50px;
  
    }
    #counter-section {
        margin-right: 30px;
    }
   
    #background-image {
        /* background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(./../images//img_10.jpg); */
        /* background-attachment: fixed; */
        width: 100%;
        height: 100%;
        z-index: -1;
        /* top: 0; */
        left: 0;
        overflow: hidden;
        position: absolute;
        background-color: black;
    }
    #background-image img{
        /* margin-top: -10vh !important; */
        padding: 0;
        margin: 0;
        filter: brightness(35%) blur(0px);
        width: 100%;
        /* height: 80vh; */
        /* min-height: 600px; */
        object-fit: cover;
        /* background-position: 0% 100px; */
    }
    #hero-section {
        text-align: center;
        height: calc(75vh + 32px);
        /* background-image: url(./../images//img_10.jpg); */
        padding-bottom: 0;
        background-color: transparent;
        margin: 0;
       
    }
    .site-section, .BigAndMiniSection {
        background-color: white;
    }
   
    .hero-content {
        color: white;
  
        margin-top: 10vh;
        position: relative;
        text-align: center;
    }
    #hero-section h1{
        /* z-index: 10;
        margin-top: 20%;
        margin-top: -50vh;
        position: relative; */
        color: white;
        font-size: 60px;
        /* max-width: 500px; */
        /* text-align: center; */
        margin: auto;
        padding-bottom: 8px;
  
    }
    #hero-section h2{
        color: rgb(230, 230, 230);
        font-size: 27px;
  
    }
    #full {
        width: 100%;
        height: 100%;
    }
    .availability-style {
  
    }
    .availability-style p {
        margin-left: 6px;
        margin-right: 10px;
        width: 20%;
    }
    .availability-style .form-check {
        margin-left: 6px;
        margin-right: 10px;
    }
    .submit-button {
        /* color: #eebc27 !important; */
        color: white;
        border: 2px solid #eebc27;  
        padding: 8px 32px;
        /* float: left; */
        margin-right: 10px;
        margin-top: -7px;
        line-height: 1.9;
    }
    .submit-button:hover {
        background: #eebc27;
        color: white !important;  
    }
    .back-button {
        color:  rgb(187, 187, 187);
        border: 2px solid rgb(187, 187, 187) !important;  
        padding: 8px 34px;
        /* float: left; */
        margin-right: 10px;
    }
    .back-button:hover {
        background:  rgb(187, 187, 187) !important;
        color: white;  
    }
    .next-button {
        /* float: right; */
       
        color: white;
        background-color: #ffc107;
        border: 2px solid #ffc107;  
        padding: 16px 48px;
    }
    .next-button:hover {
        background: #8a7022;
        border: 2px solid #8a7022;  
  
    }
    .next-button:active {
        background: #534519;
        border: 2px solid #534519;  
  
    }
    #news {
        // background:$primary-color;
        padding-top: 15px;
        padding-bottom: 70px;
        /* padding-bottom: 0%; */
        border-top: 1px solid rgb(231, 231, 231);
        /* background: rgb(221, 221, 221); */
        h1 {
            margin: 30px 0 10px 0;
            font-size: 20px;
            color: gray;
            
            // display: none;
        }
    }
    #partners {
        // padding-top: 9%;
  
        padding-bottom:0;
    }
    #news, #partners {
        /* background-color: #01295F; */
    }
    #news img,#partners img{
        filter: grayscale(100%);
        transition: .75s;
  
    }
    #news img:hover,#partners img:hover {
        filter: grayscale(100%);
        transition: .75s;
  
    }
    .border-right {
        border-right: 1px solid gray;
    }
    @media (max-width: 576px) {
        .mobile-border-top {
            margin-top: 50px;
            padding-top: 20px;
            /* border-top: 1px solid gray; */
        }
        #margin-left {
            margin-left: 0;
        }
        .footer {
            padding-top: 3em;
        }
  
  
       
    }
   
    .social-card {
        text-align: left;
    }
    .big {
        width: 35% !important;
    }
    #news img{
        width: 55%;
        margin: 20px 0;
        text-align: center;
        @include verticalAlignMiddle();
    }
    .privacy-style {
        background: white;
    }
    .card-style {
        /* margin: auto; */
        /* margin-left: 10px;
        margin-right: 10px; */
        text-align: left;
        /* width: 100%; */
        display: inline-block;
        /* width: 30vw; */
    }
    @media (min-width: 758px) {
        .margin-right {
            padding-right: 50px;
        }
        
    }
    @media (max-width: 700px) {
        #hero-section h1{ 
            font-size: 5vh !important;
        }
        #hero-section h2{ 
            font-size: 3vh !important;
        }
    }
    .btn-darkyellow {
        background: green;
    }
    .subdued-link {
        color: gray;
    }
    .card-subtitle {
        margin-bottom: 8px;
    }
    #whats-new {
        margin-bottom: 5%;
    }
    .card-column {
        /* margin: 0; */
    }
    .card-img-top {
        height: 30vh !important;
        object-fit: cover;
    }
    #align-left {
        text-align: left;
    }
    .post-style {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    @media (min-width: 758px) {
        .post-style {
            width: 60%;
        }
    }
    #light-background {
        background-color: rgb(221, 182, 7);
    }
    .heading-dark {
        font-size: 50px;
        margin-top: 50px;
    }
    .margin-left {
        margin-left: 5%;
    }
    #remove-padding2 {
        padding-top: 0;
    }
    .steps-image {
        height: 250px;
        overflow: hidden;
    }
    .steps-image img {
        /* object-fit: contain; */
    }
   
    .forgot-password {
        background: white;
        padding:10%;
        color: black;
    }
    .forgot-password label{
        font-size: 30px;
    }
    .three-dots {
        float: right;
        cursor: pointer;
        vertical-align: middle;
        margin-top: 0px;
        font-size: 28px;
        transition: .5s;
        border-radius: 50%;
        padding: 7px;
        /* color: white; */
    }
    .three-dots:hover {
        background: rgb(231, 230, 230);
  
    }
    .three-dots:active {
        transform: scale(.9);
    }
.calendar {
    float: right;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 0px;
    font-size: 30px;
    transition: .2s;
    border-radius: 30%;
    padding: 7px;
    /* color: white; */
}
.calendar:hover {
    background: rgb(231, 230, 230);

}
.calendar:active {
    transform: scale(.9);
}

.calendar1 {
    float: right;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 0px;
    font-size: 15px;
    transition: .2s;
    padding: 7px;
    /* color: white; */
}
    .match-name {
        vertical-align: middle;
  
    }
    .popup {
        background: white;
        position: absolute;
        /* color: white; */
        z-index: 100;
        width: 18em;
        border: 1px solid gray;
        border-radius: 5px;
        /* visibility: hidden; */
        top: 46px;
    }
    .popup a{
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        display: block;
        margin: 4px;
        color: gray;
        padding: 2px 8px;
        border-radius: 5px;
  
    }
    .popup a:hover {
        background: rgb(240, 240, 240);
    }

    .popup1 {
        background: white;
        position: absolute;
        /* color: white; */
        z-index: 100;
        width: 18em;
        border: 1px solid gray;
        border-radius: 5px;
        /* visibility: hidden; */
        margin-left: 0em;
        top: 50px;
    }
.popup1 a{
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    display: block;
    margin: 4px;
    color: gray;
    padding: 2px 8px;
    border-radius: 5px;

}
.popup1 a:hover {
    background: rgb(240, 240, 240);
}

.popup2 {
    background: white;
    position: absolute;
    /* color: white; */
    z-index: 100;
    width: 20em;
    border: 1px solid gray;
    border-radius: 5px;
    /* visibility: hidden; */
    top: 110px;
}
.popup2 a{
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    display: block;
    margin: 4px;
    color: gray;
    padding: 2px 8px;
    border-radius: 5px;

}
.popup2 a:hover {
    background: rgb(240, 240, 240);
}
    /* .show {
        visibility: visible !important;
        display: none;
  
      } */
  
      .arrow-up {
        width: 0;
        height: 0;
        margin-left: 11.053rem;
        margin-top: -5px;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid gray;
      }
.arrow-up1 {
    width: 0;
    height: 0;
    margin-left: 7.4rem;
    margin-top: -6px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;
}

.arrow-up2 {
    width: 0;
    height: 0;
    margin-left: 11.2rem;
    margin-top: -5px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;
}
      .bell-icon {
          margin-right: 10px;
          margin-top: -2px;
      }
      .people-col {
          border-right: 1px solid rgba(0,0,0,.125);
          padding-right: 0;
      }
      .message-col {
          border-left: 1px solid rgba(0,0,0,.125);
          padding-left: 0;
      }
      .send-button {
          text-align: right;
         
      }
      .send-button button {
        margin-right: 10px;
        /* width: 90%; */
        margin-bottom: 20px;
       
       
    }
      .coolcolor {
          color: #02295f;
      }

    .quiz-section {
        padding: 30px;
        background: white;
        text-align: center;
    }
    .quiz-title {
        font-size: 20px;
        margin-bottom: 0;
    }
    .questionBox {
        margin-bottom: 10px;
        /* border: 1px solid gray; */
        border-radius: 5px;
        padding: 10px;
  
    }
    .question {
        font-size: 24px;
        margin-bottom: 10px;
        width: 60%;
        margin-left: 20%;
  
    }
    .answerBtn {
  
        cursor: pointer;
        padding: 30px 50px;
        font-size: 20px;
        margin: 10px;
        background: white;
        border: 2px solid rgb(238, 238, 238);
        transition: .2s;
        border-radius: 2px;
        display: block;
        width: 60%;
        margin-left: 20%;
       
    }
    .answerBtn:hover {
        /* background: black; */
        color: #a37f13;
        border: 2px solid #a37f13;
  
    }
    .playBtn {
        background: white;
        padding: 10px 20px;
        border: 1px solid #555555;
        margin-top: 20px;
        color: #555555;
        transition: .25s;
    }
    .playBtn:hover {
        /* background: #555555; */
        cursor: pointer;
        color: #a37f13;
        border: 1px solid #a37f13;
  
    }
    .playBtn:focus, .answerBtn:focus {
        /* border-color: #806209; */
        /* color:  */
        outline: none !important;
        /* color: blue; */
    }
    .playBtn:active, .answerBtn:active {
        /* color: #806209; */
        /* border-color: #806209; */
        transform: scale(.99);
    }
    .score-board {
        width: 80%;
        margin-left: 10%;
    }
    .score {
        /* font-size: 20px; */
    }
    @media (min-width: 768px) {
        .social-card {
            margin-left: 0 !important;
        }
      
  
    }
    @media (max-width: 800px) {
        .answerBtn, .question {
            width: 98%;
            margin-left: 1%;
        }
    }
    /* @media (max-width: 700px) {
        .answerBtn, .question {
            width: 98%;
            margin-left: 1%;
        }
    } */


    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    img {
        
        opacity: 0;
        animation: fadeIn .25s forwards;
    }
    .FAQ button{
        border: none;
        outline: none;
        border-radius: 1px;
    }
    .FAQ .card-header{
        background: none;
        border-bottom: none;
        font-weight: lighter;
    }
    .FAQ .card-body {
        border-top: 1px solid rgba(0,0,0,.125);

    }
 
    .testimonial-section {
        /* width: 100%; */
        /* text-align: center; */
        margin-top: 3%;
        .row {
            
        }

    }
    
    .testimonial-section h1 {
        font-size: 30px !important;
    }
    /* #hobbies .form-check label{
        cursor: pointer !important;
    }
    #hobbies .form-check input{
        cursor: pointer !important;
    }
    #big-or-mini .form-check label{
        cursor: pointer !important;
    }
    #big-or-mini .form-check input{
        cursor: pointer !important;
    } */
    .form-check-input, .form-check-label {
        cursor: pointer !important;

    }
    #socials-info {
        color: white;
        margin: "20px";
        font-size: 20px;
        display: inline-block !important;
        width: 33%;
    }
    .video-section{
        /* margin-left: 20%; */
    }
    .play-button-link {
        /* position: absolute; */
        /* margin-left: 25%; */
        /* margin-top: 10%; */
    }
    .play-button {
        left: 52%;
        /* margin-top: 500%; */
        transform: translateY(20px);
        position: absolute;
        z-index: 2;


    }
    .video-image {
        top: 0;
        position: relative;
        margin:0;
        width: 100%;
        /* margin-left: 20%; */

    }
    .news-title {
        margin-left: 20px;
        margin-bottom: 25px;
        /* -webkit-user-select: none; 
-moz-user-select: none;
-ms-user-select: none; 
user-select: none;  */

        
    }
    .clear-button {
        background-color: transparent;
        border: none;
    }
    .clear-button:focus {
        outline: none;
    }
    .link {
        color: rgb(170, 170, 170);
        transition: .5s;
    }
    .link:hover{
        color: rgb(170, 170, 170);

    }
    .dropdown-styling {
        background: black;
    }
    .dropdown-menu {
        background: black;

    }
    .dropdown-item {
        background: black;
        transition: color .5s;
    }
    .dropdown-item:hover {
        background: black;
        color: gray !important;
    }
    .dropdown-item.active {
        background: black ;
    }
    .login-dropdown {
        color: gray;
        /* font-size: 25px; */
    }
    .login-dropdown:hover {
        color: rgb(19, 19, 19);
    }
    #login-dropdown-ul {
        margin: 0;

    }
    @media (min-width: $small-width) {
        #logout-desktop {
            display: inline-block !important;
        }
        #logout-mobile {
            display: none;
        
        }
    }



    #logout-desktop {
        display: none;
    }
    .dropdown-links {
        z-index: 10;
        position: absolute;
        /* border-left: 1px solid $primary-color; */
        margin-left: 10px;
        /* display: none; */
        background: white;
        /* padding: 10px 30px 10px 10px; */
        padding: 12px 20px 12px 2px;
        border-radius: 2px;
        border: 1px solid rgb(236, 236, 236);
        box-shadow: 0 0 2px rgb(202, 202, 202);
    }
    .dropdown-links a{
        padding: 1px;
        margin-left: 5px;
        color: rgb(59, 59, 59) !important;
        font-weight: 300;
        font-size: 18px;
    }
    #hover-reaction .nav-link:hover, #dropdown-title .nav-link:hover {
        /* transition: .25s; */
      color: #c49d2b !important;
    }
    #navbar-background {
        /* margin-bottom: 0; */
        /* transform: translateY(10vh); */
        animation: growIn .5s forwards;
        /* opacity: 0; */
       
    }
    
    @keyframes growOut {
      0% {
          /* opacity: 1; */
      }
      100% {
          opacity: 0;
      }
    }
    #navbar-background-out {
      /* margin-bottom: 15vh; */
      /* animation: growIn .25s backwards; */
      animation: growOut 0s forwards;
    }
    @media (max-width: 992px) {
        #mobile-button {
            margin-top: 10px;
        }
        #hero-section h1{ 
            font-size: 6vh ;
        }
        #hero-section h2{ 
            font-size: 4vh ;
        }
        
      .dropdown-links {
          position: relative;
          display: block !important;
          border: none;
          box-shadow: none;
          padding: 0;
    
      }
      .dropdown-links a {
        /* border-left: 1px solid gray; */
        display: list-item;          /* This has to be "list-item"                                               */
        list-style-type: disc;       /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
        list-style-position: inside;
          color: rgb(156, 156, 156) !important;
          font-weight: 100;
    
      }
      .nav-item {
          margin: 0;
      }
      /* #navbar-background-out, #navbar-background {
          opacity: 1 !important;
          animation: none;
      } */
      .navbar-nav {
        /* overflow-y: scroll; */
        /* height: 80vh; */
        margin-bottom: 3vh;
    }
    .nav-link {
        padding-bottom: 0;
    }
    
    }
    
    .dropdown-links {
      display: none;
    
    }
    #dropdown-title:hover .dropdown-links{
      display: block !important;
      /* opacity: 0; */
      animation: growIn .5s forwards;
    }
    @media (min-width: 992px) {
    @keyframes growIn {
        0% {
          opacity: 0;
          transform: translateY(1vh);
        }
        50% {
            /* opacity: 50%; */
        }
        100% {
          /* opacity: 100%; */
          transform: translateY(0vh);
        }
    }
    }
    #hoverable-link:hover {
        color: #c49d2b !important;
    }
    
    
    @media (min-width: 768px) {
        .footer-column {
            float: right;
        }
    }
    .footer-column {
        padding-right: 15px;
    }
    
    .footer-link {
        color: white !important;
    }
