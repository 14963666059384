$primary-color: #237d26;
$hover-color: #09410b;
$secondary-color: #02295f;

// $primary-color: #bf5700;
// $hover-color: #924303;


$border-color: #D3D3D3;
$darker-border-color: #868686;
$border-radius: 3px;
$lighter-border-color: rgb(226, 226, 226);

$medium-width: 768px;
$small-width: 768px;


// font-sizes for onboarding
// 1 or 1.4
$scaler: 2; // for scaling the font-sizes
$title-fs: 25px;
$subtitle-fs: 18px ;
$formfield-labels-fs: 18px ;
$button-fs: 16px ;
$disclaimer-fs: 13px;
$paragraph-fs: 16px ;



$scaler2: 1.15; // for dashboard
// 1 or 1.2
// 50, 30, 27, 25, 24, 23, 16, 15, 14, 12
$fs-xl: 50px * $scaler2;
$fs-title: 30px * $scaler2;
$fs-subtitle-1: 27px * $scaler2;
$fs-subtitle-2: 25px * $scaler2;
$fs-subtitle-3: 23px * $scaler2;
$fs-subtitle-4: 20px * $scaler2;
$fs-p-1: 16px * $scaler2;
$fs-p-2: 14px * $scaler2;
$fs-p-3: 12px * $scaler2;

$invite-link-width: 600px * $scaler2;
$nav-link-padding: 9px * $scaler2;
$fs-navbar: 18px * $scaler2;

// $scaler3: 2;
// // large fonts
// $lg-xl: 50px * $scaler3;
// $lg-title: 30px * $scaler3;
// $lg-subtitle-1: 27px * $scaler3;
// $lg-subtitle-2: 25px * $scaler3;
// $lg-subtitle-3: 23px * $scaler3;
// $lg-subtitle-4: 20px * $scaler3;
// $lg-p-1: 16px * $scaler3;
// $lg-p-2: 14px * $scaler3;
// $lg-p-3: 12px * $scaler3;



.large {
    
}
.fs-toggle {
    background: black;
    height: 50px;
    width: 215px;
    white-space: nowrap;
    position: fixed;
    left: 0;
    bottom: 10vh;
    background: white;
    border: 1px solid $secondary-color;
    border-left: none;
    border-radius: 0 3px 3px 0px;
    transition: .25s;
    z-index: 20;
    transform: translateX(-165px);

    svg {
        font-size: 20px;
        font-weight: bold;
        margin-top: -4px;
        margin-right: 5px;
    }
    .explanation {
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        margin: 0 15px 0 0;
        text-transform: capitalize;
    }
}
.fs-toggle:hover {
    background: $secondary-color;
    color: white;
    transform: translateX(0px);
}
.fs-toggle:active {
    
}
.active-fs-toggle {
    background: $primary-color;
}
.active-fs-toggle:hover {
    color: black;
    background: $primary-color;
}








